header {
  background-color: #fff9ed;
  width: 100%; /* 전체 화면 너비에 맞춤 */
  display: flex;
  justify-content: center; /* 가로 방향으로 중앙 정렬 */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-sizing: border-box; /* 패딩과 보더를 포함한 전체 너비 계산 */
}

.header-content {
  max-width: 1200px; /* 최대 너비 설정 */
  width: 100%; /* 전체 너비에 맞춤 */
  display: flex;
  justify-content: space-between; /* 로고와 나머지 요소들을 양쪽 끝으로 정렬 */
  align-items: center;
  padding: 10px 20px; /* 패딩이 추가적인 너비를 차지할 수 있음 */
  box-sizing: border-box; /* 패딩과 보더를 포함한 전체 너비 계산 */
  margin: 0 auto; /* 가운데 정렬 */
}

.logo img {
  width: 80px; /* 로고 이미지 너비 조절 */
  height: auto; /* 높이를 자동으로 설정하여 비율 유지 */
}

nav, .search-input {
  display: flex;
  align-items: center;
}

nav {
  display: flex;
  align-items: center;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  margin-left: 20px;
}

nav ul li a {
  color: #086ac5;
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.2s ease;
}

nav ul li a:hover {
  color: #ffc400;
}

.search-input {
  margin-left: 20px;
  padding: 5px;
  background-color: #f5ebd6;
  border: 1px solid #086ac5;
  color: #007adf;
}