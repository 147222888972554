.App {
  background-color: #fffbf7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center; /* 중앙 정렬을 위해 추가 */
}

.content-wrapper {
  width: 1200px; /* 고정 너비 유지 */
  margin: 0 auto; /* 중앙 정렬 유지 */
  padding: 60px 20px 0; /* 상단 패딩은 헤더 높이만큼, 좌우 패딩 추가 */
  box-sizing: border-box; /* 패딩을 너비에 포함 */
}

main {
  width: 100%; /* 부모 요소에 맞춤 */
  color: #086ac5;
}