.news-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.news-form input[type="text"],
.news-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #e0f0ff;
    border-radius: 4px;
    font-size: 16px;
}

.news-form textarea {
    min-height: 200px;
    resize: vertical;
}

.news-form input[type="file"] {
    margin-bottom: 20px;
}

.form-buttons {
    display: flex;
    justify-content: flex-end;
}

.form-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.form-buttons button[type="submit"] {
    background-color: #086ac5;
    color: white;
    margin-right: 10px;
}

.form-buttons button[type="button"] {
    background-color: #dc3545;
    color: white;
}

.form-buttons button:hover {
    opacity: 0.8;
}