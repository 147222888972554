.news-detail {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 30px;
    padding: 40px 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.news-title {
    font-size: 28px;
    color: #086ac5;
    margin-bottom: 10px;
    border-bottom: 2px solid #e0f0ff;
    padding-bottom: 10px;
}

.news-date {
    color: #0059ac;
    font-size: 14px;
    margin-bottom: 20px;
}

.news-image {
    max-width: 100%;
    height: auto;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.news-content {
    font-size: 16px;
    line-height: 1.6;
    color: #333333;
    margin-top: 10px;
    white-space: pre-line;
}

.news-content p {
    margin-bottom: 15px;
}

