.news-management {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.news-management h2 {
    color: #333;
    margin-bottom: 20px;
}

.create-news-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
}

.create-news-btn:hover {
    background-color: #45a049;
}

.news-list {
    list-style-type: none;
    padding: 0;
}

.news-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.news-item h3 {
    margin-top: 0;
    color: #2c3e50;
}

.news-content {
    white-space: pre-wrap;
    word-break: break-word;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.6;
}

.news-image {
    max-width: 100%;
    max-height: 300px; /* 원하는 최대 높이로 조정하세요 */
    width: auto;
    height: auto;
    object-fit: contain;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.news-image-container {
    display: flex;
    justify-content: center; /* 가운데 정렬 */
    align-items: center;
    max-height: 300px; /* .news-image의 max-height와 동일하게 설정 */
    overflow: hidden;
}
.news-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.edit-btn, .delete-btn {
    padding: 5px 10px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.edit-btn {
    background-color: #3498db;
    color: white;
}

.edit-btn:hover {
    background-color: #2980b9;
}

.delete-btn {
    background-color: #e74c3c;
    color: white;
}

.delete-btn:hover {
    background-color: #c0392b;
}

.news-date {
    font-size: 12px;
    color: #7f8c8d;
    margin-bottom: 10px;
}

@media (max-width: 600px) {
    .news-management {
        padding: 10px;
    }

    .news-item {
        padding: 10px;
    }

    .news-actions {
        flex-direction: column;
    }

    .edit-btn, .delete-btn {
        margin-left: 0;
        margin-top: 5px;
        width: 100%;
    }
}