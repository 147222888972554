.request-form {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.request-form input[type="text"],
.request-form input[type="email"],
.request-form input[type="password"],
.request-form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.request-form textarea {
  min-height: 400px;
  resize: vertical;
}

.visibility-options {
  display: flex;
  gap: 20px;
}

.visibility-options label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.request-form button[type="submit"] {
  align-self: flex-start;
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.request-form button[type="submit"]:hover {
  background-color: #45a049;
}