.sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1000;
    display: flex;
    transition: transform 0.1s ease-in-out;
    transform: translateX(-100%);
}

.sidebar-trigger {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
    width: 20px;
    height: 50px;
    background-color: rgba(1, 73, 155, 0.438);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    border-radius: 0 5px 5px 0;
    transition: opacity 0.1s ease;
    z-index: 1001;
}

.sidebar {
    width: 300px;
    height: 100%;
    background-color: #fff9ed;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    padding: 20px;
}

.sidebar-container:hover {
    transform: translateX(0);
}

.sidebar-container:hover .sidebar-trigger {
    opacity: 0;
    pointer-events: none;
}

.tag-categories {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.tag-category {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
    background-color: #ffffff;
    height: 300px;
    display: flex;
    flex-direction: column;
}

.tag-category h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 16px;
    color: #086ac5;
    display: flex;
    align-items: center;
    gap: 10px;
}

.selected-tag {
    font-size: 12px;
    color: white;
    padding: 2px 6px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.selected-tag.description {
    background-color: #5b8dc7;
}

.selected-tag.genre {
    background-color: #5198c7;
}

.tag-list {
    flex: 1;
    overflow-y: auto;
    padding-right: 5px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 5px;
}

.tag-list::-webkit-scrollbar {
    width: 5px;
}

.tag-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.tag-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.tag-filter-button {
    border: none;
    border-radius: 16px;
    padding: 3px 10px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    white-space: nowrap;
}

.tag-filter-button.description {
    background-color: #e7f4ff;
    color: #5b8dc7;
}

.tag-filter-button.genre {
    background-color: #e8faff;
    color: #5198c7;
}

.tag-filter-button:hover, .tag-filter-button.active {
    color: #ffffff;
}

.tag-filter-button.description:hover, .tag-filter-button.description.active, .selected-tag.description {
    background-color: #5b8dc7;
    color: #ffffff;
}

.tag-filter-button.genre:hover, .tag-filter-button.genre.active, .selected-tag.genre {
    background-color: #5198c7;
    color: #ffffff;
}

.clear-tags-button {
    background-color: #f0f0f0;
    border: none;
    padding: 2px 5px;
    border-radius: 10px;
    font-size: 12px;
    cursor: pointer;
    margin-left: 10px;
}

.clear-tags-button:hover {
    background-color: #e0e0e0;
}