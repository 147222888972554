.news-page {
    max-width: 1200px;
}

.news-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.news-page-title {
    font-size: 24px;
    color: #1e73ce;
    margin: 0;
}




.news-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 한 줄에 5개 게시물 */
    gap: 20px;
}

.loading, .error {
    text-align: center;
    font-size: 18px;
    color: #086ac5;
    margin-top: 50px;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.pagination button {
    margin: 0 5px;
    padding: 8px 12px;
    border: 1px solid #086ac5;
    background-color: white;
    color: #0962b4;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 5px;
}

.pagination button.active, .pagination button:hover {
    background-color: #086ac5;
    color: white;
}
