.recent-albums-section {
  width: 100%; /* 전체 너비 사용 */
  max-width: 1200px; /* 최대 너비 설정 */
  margin-top: 20px;
  border-top: 1px solid #e0e0e0; /* 상단에 얇은 회색 라인 추가 */
  padding-top: 20px; /* 라인과 섹션 내용 사이에 약간의 패딩 추가 */
  box-sizing: border-box; /* 패딩과 보더를 포함한 전체 너비 계산 */
}

.recent-albums-section h2 {
  color: #0059ac;
  margin-bottom: 20px;
}

.recent-albums-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.recent-album-card {
  width: 224px;  /* 5개 앨범이 한 줄에 나오도록 수정 */
  margin-bottom: 20px;
  background-color: #ffffff;  /* 배경색을 좀 더 어둡게 조정 */
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  color: #01499b;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.recent-album-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.recent-album-artwork {
  width: 100%;
  padding-top: 100%;  /* 정사각형 비율 유지 */
  position: relative;
  overflow: hidden;
}

.recent-album-artwork img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recent-album-card h3 {
  padding: 10px 10px 5px;  /* 하단 패딩을 줄임 */
  margin: 0;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recent-album-card p {
  padding: 0 10px 10px;
  margin-top: 0;  /* 상단 마진 제거 */
  font-size: 12px;
  color: #686868;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2.4em;
}

.recent-tag-container {
  padding: 0 10px 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.recent-tag-button {
  background-color: #e0f1ff;
  border: none;
  border-radius: 12px;
  padding: 3px 8px;
  font-size: 11px;
  color: #3982d6;
  cursor: pointer;
  transition: background-color 0.3s;
}

.recent-tag-button:hover {
  background-color: #003388;
  color: #bed6f1;
}

.recent-tag-button-genre {
  background-color: #e8faff;
  border: none;
  border-radius: 12px;
  padding: 3px 8px;
  font-size: 11px;
  color: #6399bd;
  cursor: pointer;
  transition: background-color 0.3s;
}

.recent-tag-button-genre:hover {
  background-color: #0089ac;
  color: #f2faff;
}