.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #faf7ed;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .modal-content h2 {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .modal-content form {
    display: flex;
    flex-direction: column;
  }
  
  .modal-content input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .modal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .modal-buttons button[type="submit"] {
    background-color: #5fac61;
    color: white;
  }
  
  .modal-buttons button[type="submit"]:hover {
    background-color: #45a049;
  }
  
  .modal-buttons button[type="button"] {
    background-color: #db665e;
    color: white;
  }
  
  .modal-buttons button[type="button"]:hover {
    background-color: #c74c44;
  }