.album-page {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

.sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1000;
    display: flex;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
}

.sidebar-trigger {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
    width: 20px;
    height: 40px;
    background-color: rgba(1, 73, 155, 0.438);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    border-radius: 0 5px 5px 0;
    transition: opacity 0.3s ease;
    z-index: 1001;
}

.sidebar {
    width: 300px;
    height: 100%;
    background-color: #fff9ed;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    padding: 20px;
}

.sidebar-container:hover {
    transform: translateX(0);
}

.sidebar-container:hover .sidebar-trigger {
    opacity: 0;
    pointer-events: none;
}

.tag-categories {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.tag-category {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
    background-color: #ffffff;
    height: 300px;
    display: flex;
    flex-direction: column;
}

.tag-category h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 16px;
    color: #01499b;
    display: flex;
    align-items: center;
    gap: 10px;
}

.selected-tag {
    font-size: 12px;
    color: white;
    padding: 2px 6px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.selected-tag.description {
    background-color: #5b8dc7;
}

.selected-tag.genre {
    background-color: #5198c7;
}

.tag-list {
    flex: 1;
    overflow-y: auto;
    padding-right: 5px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 5px;
}

.tag-list::-webkit-scrollbar {
    width: 5px;
}

.tag-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.tag-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.tag-filter-button {
    border: none;
    border-radius: 16px;
    padding: 3px 10px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    white-space: nowrap;
}

.tag-filter-button.description {
    background-color: #e7f4ff;
    color: #5b8dc7;
}

.tag-filter-button.genre {
    background-color: #e8faff;
    color: #5198c7;
}

.tag-filter-button:hover, .tag-filter-button.active {
    color: #ffffff;
}

.tag-filter-button.description:hover, .tag-filter-button.description.active, .selected-tag.description {
    background-color: #5b8dc7;
    color: #ffffff;
}

.tag-filter-button.genre:hover, .tag-filter-button.genre.active, .selected-tag.genre {
    background-color: #5198c7;
    color: #ffffff;
}
.albums-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    margin-bottom: 50px; 
}

.album-card {
    width: 224px;
    background-color: #fffffe;
    border-radius: 8px;
    overflow: hidden;
    text-decoration: none;
    color: #086ac5;
    transition: transform 0.3s ease;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.album-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.album-artwork {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
}

.album-artwork img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.album-card h3 {
    padding: 10px 10px 5px;
    margin: 0;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.album-card p {
    padding: 0 10px 10px;
    margin-top: 0;
    font-size: 12px;
    color: #686868;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 2.4em;
}

.tag-container {
    padding: 0 10px 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.tag-button {
    border: none;
    border-radius: 12px;
    padding: 3px 8px;
    font-size: 11px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.tag-button.description {
    background-color: #e7f4ff;
    color: #5b8dc7;
}

.tag-button.genre {
    background-color: #e8faff;
    color: #5198c7;
}

.tag-button:hover {
    opacity: 0.8;
}

.tag-button.description:hover, .tag-button.description.active {
    background-color: #3a6fca;
    color: #d2edff;
}

.tag-button.genre:hover, .tag-button.genre.active {
    background-color: #469bb1;
    color: #f2faff;
}

.tag-button.active {
    font-weight: bold;
}



.loading, .error {
    text-align: center;
    font-size: 18px;
    margin-top: 50px;
    color: #01499b;
}


.album-page-header {
    display: flex;
    align-items: flex-end;  /* 변경: center에서 flex-end로 */
    margin-bottom: 20px;
}

.album-page-header h1 {
    margin-right: 20px;
    margin-bottom: 0;  /* 추가: 제목 아래 여백 제거 */
    
}

.active-tags {
    display: flex;
    gap: 5px;
    align-items: center;
    padding-bottom: 10px;  /* 추가: 버튼을 약간 아래로 내림 */
}

.active-tag-button {
    border: none;
    border-radius: 12px;
    padding: 2px 7px;
    font-size: 11px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    align-items: center;
}

.active-tag-button.description {
    background-color: #315ca7;
    color: #d2edff;
}

.active-tag-button.genre {
    background-color: #55b4cc;
    color: #e7f3fa;
}

.active-tag-button:hover {
    opacity: 0.8;
}

.active-tag-button::after {
    content: '✕';
    margin-left: 5px;
    font-size: 10px;
}
.no-results {
    text-align: center;
    font-size: 18px;
    color: #666;
    margin-top: 50px;
    padding: 20px;

    border-radius: 8px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease-in-out;
}

.fade-in {
    opacity: 1;
}

.fade-out {
    opacity: 0;
}

.reset-all-tags-button {
    background-color: #f0f0f0;
    border: none;
    padding: 5px 5px;
    border-radius: 15px;
    font-size: 12px;
    cursor: pointer;
    margin-left: 10px;
    color: #333;
}

.reset-all-tags-button:hover {
    background-color: #e0e0e0;
}