.news-item {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    height: 90%;
}

.news-item:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
    transform: translateY(-5px);
}

.news-item-image {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #086ac52d;
}

.news-item-content {
    padding: 5%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.news-item-title {
    font-size: 15px;
    color: #005db4;
    margin: 0 0 0 0;
    font-weight: bold;
    line-height: 1.3;
}

.news-item-date {
    font-size: 14px;
    color: #005db4;
    margin-bottom: -10px;
    margin-top: 10px;
    align-self: flex-end;
}

.news-item-excerpt {
    color: #005db4;
    background-color: #ffffff;

    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 15px;
    flex-grow: 1;
    border: 1px dashed #086ac52d;
    padding: 10px;
    border-radius: 4px;
}

.news-item-readmore {
    align-self: center;
    width: 90%;
    background-color: #086ac5;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    transition: background-color 0.3s ease;
}

.news-item:hover .news-item-readmore {
    background-color: #ffae00;
    color: white;
}

.news-item-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px 0;
  }
  
  .news-item-image {
    width: 90%;
    height: 100px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }